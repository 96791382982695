const preferredTitleText = 'Turime tau kai ką pasakyti. Ir tai visai svarbu.';
const preferredTextHtml = '<strong>Šioje svetainėje naudojami slapukai. Jie reikalingi, kad pagerintų tavo naršymo patirtį joje. Jei tęsi savo naršymą čia, priimsime tai kaip sutikimą su mūsų slapukų naudojimo politika.</strong>';

const replacementFunction = () => {
    document.getElementById('cc-nb-title').innerText = preferredTitleText;
    document.getElementById('cc-nb-text').innerHTML = preferredTextHtml;
};

document.addEventListener('cc_noticeBannerShown', () => {
    console.log('cc_noticeBannerShown');

    replacementFunction();

    setTimeout(replacementFunction, 0);
    setTimeout(replacementFunction, 10);
    setTimeout(replacementFunction, 100);
    setTimeout(replacementFunction, 500);
    setTimeout(replacementFunction, 1000);

    // const $text = $('.freeprivacypolicy-com---nb-interstitial-overlay #cc-nb-text');
    //
    // while ($text.html() !== preferredHtml) {
    //     $text.html(preferredHtml);
    //
    //     console.log('replacing');
    // }
});
